module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Top","exclude":["**/dev-404-page/**","**/404/**"],"crumbLabelUpdates":[{"pathname":"/news","crumbLabel":"News / Event"}]},
    },{
      plugin: require('../node_modules/@imgix/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"domain":"","defaultImgixParams":{"auto":["compress","format"]},"fields":[{"nodeType":"MicrocmsSlideImages","fieldName":"imgixSlide1","rawURLKey":"slide1.url"},{"nodeType":"MicrocmsSlideImages","fieldName":"imgixSlide2","rawURLKey":"slide2.url"},{"nodeType":"MicrocmsSlideImages","fieldName":"imgixSlide3","rawURLKey":"slide3.url"},{"nodeType":"MicrocmsSlideImages","fieldName":"imgixSlide4","rawURLKey":"slide4.url"},{"nodeType":"MicrocmsExhibitors","fieldName":"imgixImage","rawURLKey":"image.url"},{"nodeType":"MicrocmsHostelImages","fieldName":"imgixImage","rawURLKey":"image.url"},{"nodeType":"MicrocmsNews","fieldName":"imgixImage","rawURLKey":"image.url"},{"nodeType":"MicrocmsNeighborhoods","fieldName":"imgixImage","rawURLKey":"image.url"}]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
